
































































































































































































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import Methods from '@/components/methods'
import { post, postFile, get } from '@/http'
import { alert } from '@/components/pop'

@Component
export default class SubmitMessage extends Mixins(Methods) {
    can: boolean = false
    provinceShow: boolean = false
    cityShow: boolean = false
    areaShow: boolean = false
    provinceList: any = []
    cityList: any = []
    areaList: any = []
    province: string = ''
    provinceId: string = ''
    city: string = ''
    cityId: string = ''
    area: string = ''
    areaId: string = ''
    type: string = ''
    skill: any = []
    myMessage: string = ''
    image: any = null
    imgurl: any = null
    storeName: string = ''
    contactName: string = ''
    detailedAddress: string = ''
    areaShow1: boolean = true
    loading: boolean = false
    queryUrl: string = ''
    details: any = {}
    toSid: number = 0
    typeList1: any = [
		{
			name: '产品/UI设计'
		},
		{
			name: '软件开发'
		},
		{
			name: '微信开发'
		},
		{
			name: 'H5开发'
		},
		{
			name: 'APP开发'
		},
		{
			name: '网站建设'
		}
	]
    typeList2: any = [
		{
			name: '模板建站'
		},
		{
			name: '网站二次开发'
		},
		{
			name: '前端开发'
		},
		{
			name: '网站安全/维护'
		},
		{
			name: '网站定制开发'
		},
		{
			name: '后端开发'
		},
        {
			name: '测试服务'
		},
		{
			name: '手机网站'
		},
		{
			name: '企业管理软件开发'
		},
		{
			name: '工具软件'
		},
		{
			name: '行业软件开发'
		},
		{
			name: '嵌入式软件'
		},
        {
			name: '软件汉化'
		},
		{
			name: '公众平台开发'
		},
		{
			name: '小程序开发'
		},
		{
			name: '其它微信开发'
		},
		{
			name: '企业微信开发'
		},
		{
			name: '网站UI设计'
		},
        {
			name: '软件界面设计'
		},
		{
			name: '移动应用UI设计'
		},
		{
			name: '产品规划'
		},
		{
			name: 'APP开发'
		},
		{
			name: 'H5开发'
		}
	]

    created(): void {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
        if (this.$route.name === 'SubmitMessage') {
            this.can = true
            this.getProvince()
        } else {
            this.getDetail()
        }
    }
    openProvince(): void {
        this.provinceShow = !this.provinceShow
    }
    openCity(): void {
        this.cityShow = !this.cityShow
    }
    openArea(): void {
        this.areaShow = !this.areaShow
    }
    getDetail(): void  {
        get('store/my', {
			userId: sessionStorage.getItem('userId')
		}).then((res: any) => {
			this.details = res.data
            this.imgurl = this.details.icon
            this.storeName = this.details.name
            this.province = this.details.provinceName
            this.city = this.details.cityName
            this.area = this.details.areaName
            this.myMessage = this.details.desc
            this.detailedAddress = this.details.address
            this.contactName = this.details.contacts
            this.contactPhone = this.details.phone
            this.type = this.details.type
            this.skill = this.details.skills
            this.provinceId = this.details.provinceId
            this.cityId = this.details.cityId
            this.areaId = this.details.areaId
            if (['北京', '天津', '上海', '重庆'].includes(this.province)) {
                this.areaShow1 = false
            }
		})
    }
    getProvince(): void {
        post('store/citys', {
			parentId: 1
		}).then((res: any) => {
			this.provinceList = res.data
			this.province = res.data[0].areaName
            this.provinceId = res.data[0].areaId
            this.getCity()
		})
    }
    getCity(): void {
        post('store/citys', {
			parentId: this.provinceId
		}).then((res: any) => {
			this.cityList = res.data
			this.city = res.data[0].areaName
            this.cityId = res.data[0].areaId
            this.getArea()
		})
    }
    getArea(): void {
        if (['北京', '天津', '上海', '重庆'].includes(this.province)) {
            this.areaShow1 = false
            return
        }
        this.areaShow1 = true
        post('store/citys', {
			parentId: this.cityId
		}).then((res: any) => {
			this.areaList = res.data
            this.areaId = res.data[0].areaId
			this.area = res.data[0].areaName
		})
    }
    selectProvinces(name: string, id: number): void {
        this.province = name
        this.provinceId = id + ''
        this.provinceShow = false
    }
    selectCitys(name: string, id: number): void {
        this.city = name
        this.cityId = id + ''
        this.cityShow = false
    }
    selectAreas(name: string, id: number): void {
        this.area = name
        this.areaId = id + ''
        this.areaShow = false
    }
    uploadImage(e: Event): void {
        this.image = (e.target as any).files[0]
        if (this.image.size > 5*1024*1024) {
            alert({text: '上传店铺图最大为5M', state: 1})
            return
        }
        const reader = new FileReader()
        reader.readAsDataURL(this.image)
        const _ = this
        reader.onload = function() {
            const r = this.result as any
            const url = r.substring(r.indexOf("," + 1))
            _.imgurl = url
        }
    }
    submitMessage(): void {
        if (this.can && !this.image) {
            alert({text: '请上传店铺封面', state: 1})
            return
        }
        if (this.storeName.replace(/[, ]/g, '').length <= 0) {
            alert({text: '请输入店铺名称', state: 1})
            return
        }
        if (this.detailedAddress.replace(/[, ]/g, '').length <= 0) {
            alert({text: '请输入详细地址', state: 1})
            return
        }
        if (this.myMessage.replace(/[, ]/g, '').length <= 0) {
            alert({text: '请简单介绍自己店铺信息', state: 1})
            return
        }
        if (this.contactName.replace(/[, ]/g, '').length <= 0) {
            alert({text: '请填写联系人姓名', state: 1})
            return
        }
        if (!this.canSend2) {
            alert({text: '请填写联系人手机号码', state: 1})
            return
        }
        if (!this.type) {
            alert({text: '请选择您的类目', state: 1})
            return
        }
        if (!this.skill) {
            alert({text: '请选择您的服务', state: 1})
            return
        }
        this.loading = true
        this.noScroll()
        const userId: any = sessionStorage.getItem('userId')
        const param = new FormData()
        param.append('iconFile', this.image)
        param.append('userId', userId)
        param.append('name', this.storeName)
        param.append('type', this.type)
        param.append('skill', this.skill)
        param.append('desc', this.myMessage)
        param.append('provinceId', this.provinceId)
        param.append('cityId', this.cityId)
        param.append('areaId', this.areaId)
        param.append('address', this.detailedAddress)
        param.append('contacts', this.contactName)
        param.append('phone', this.contactPhone)
        let mesText: string = ''
        if (this.can) {
            this.queryUrl = 'store/save'
            mesText = '店铺已创建成功'
        } else {
            this.queryUrl = 'store/update'
            mesText = '店铺已修改成功'
            param.append('sid', this.details.sid)
        }
        postFile(this.queryUrl, param
		).then((res: any) => {
            sessionStorage.setItem('createStore', '1')
            this.loading = false
            this.canScroll()
            alert({text: mesText, state: 1})
            this.toSid = res.data.sid
            setTimeout(this.gotoShopDetails, 3000)
		})
    }
    gotoShopDetails(): void {
        this.gotoOtherPage('ShopDetails', this.toSid)
    }
    @Watch('province')
    provinceChange(): void {
        if (this.can) {
            this.getCity()
        }
    }
    @Watch('city')
    cityChange(): void {
        if (this.can) {
            this.getArea()
        }
    }
}
